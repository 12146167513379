import * as React from "react";
import DefaultLayout from "../components/DefaultLayout";

// markup
const DatenschutzPage = () => {
  return (
    <DefaultLayout>
      <title>Datenschutzerklärung</title>
      <p>Datenschutzerklärung</p>
    </DefaultLayout>
  );
};

export default DatenschutzPage;
